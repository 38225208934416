export default {
  methods: {
    scrollToMissingFields () {
      this.$nextTick(() => {
        const firstMissingField = document.querySelector('.is-invalid')
        if (!firstMissingField) return
        firstMissingField.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
      })
    },
    scrollToField (fieldId) {
      this.$nextTick(() => {
        const address = document.querySelector('#' + fieldId)
        address.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      })
    }
  }
}

<template>
  <div>
    <p class='text-left font-weight-bold mt-5'><u>{{sectionNumber}}. {{ $t('main-form.consent-data.title') }}</u></p>
      <fieldset class='fieldset-border'>
      <div class='box-wrapper padding-top-15'>
        <div class="main-form">
          <!-- <p class='terms-font-size font-weight-bold text-left'>
            <u>{{ $t('main-form.consent-data.data-processing-title') }}</u>
          </p>
          <p class='terms-font-size text-left'>
            {{ $t('main-form.consent-data.data-processing-paragraph') }}
          </p> -->
          <div>
            <div style='margin:1rem 0;'>
              <b-link v-b-toggle="'collapse-2'" @click='toggleReadMore' class="m-1 terms-font-size read-more-link">{{ isReadMoreActive }}</b-link>
            </div>
            <b-collapse id="collapse-2">
              <b-card>
                <p class='terms-font-size text-left'>
                  {{ $t('main-form.consent-data.hereby-agree') }}
                </p>
                <p class='terms-font-size font-weight-bold text-left'>
                  <u>{{ $t('main-form.consent-data.data-protection-title') }}</u>
                </p>
                <p class='terms-font-size text-left'>
                  {{ $t('main-form.consent-data.data-protection-paragraph') }}
                </p>
                <ul>
                  <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.second-ul-li-1') }}</li>
                  <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.second-ul-li-2') }}</li>
                  <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.second-ul-li-3') }}</li>
                  <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.second-ul-li-4') }}</li>
                </ul>
                <p v-if="$i18n.locale === 'en'" class='terms-font-size text-left'>
                  {{ $t('main-form.consent-data.footer-paragraph-1') }}
                </p>
                <p class='terms-font-size text-left'>
                  {{ $t('main-form.consent-data.footer-paragraph-2') }}
                </p>
                <p class='terms-font-size text-left'>
                  {{ $t('main-form.consent-data.footer-paragraph-3') }}
                </p>
                <b-link href="https://schooltransport.edu.mt/?page_id=657" target="_blank" class="m-1 terms-font-size text-left">https://schooltransport.edu.mt/?page_id=657</b-link>
                <p class='terms-font-size text-left mt-2'>
                  {{ $t('main-form.consent-data.footer-paragraph-4') }}
                </p>
                <p class='terms-font-size text-left'>
                  E-mail:<a href='mailto:dpo.meyr@gov.mt'> dpo.MEYR@gov.mt</a>
                </p>
                <!-- <p class='terms-font-size text-left'>
                  Tel: 25982362
                </p> -->
                <template v-if="visibleProcessing">
                  <p class='terms-font-size font-weight-bold text-left'>
                    <u>{{ $t('main-form.consent-data.data-processing-title') }}</u>
                  </p>
                  <p class='terms-font-size text-left'>
                    {{ $t('main-form.consent-data.data-processing-paragraph') }}
                  </p>
                  <ul>
                    <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.first-ul-li-1') }}</li>
                    <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.first-ul-li-2') }}</li>
                    <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.first-ul-li-3') }}</li>
                    <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.first-ul-li-4') }}</li>
                    <li class='terms-font-size text-left'>{{ $t('main-form.consent-data.first-ul-li-5') }}</li>
                  </ul>
                </template>
              </b-card>
            </b-collapse>
          </div>
          <slot name='termsOfUse'></slot>
          <div v-show="submitted && !termsOfUse" class="invalid-feedback-custom">{{ $t('main-form.consent-data.accept-terms-error') }}</div>
        </div>
      </div>
      <slot name='submitButton'></slot>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    termsOfUse: Boolean,
    submitted: Boolean,
    sectionNumber: String,
    visibleProcessing: Boolean
  },
  data () {
    return {
      readMore: true
    }
  },
  methods: {
    toggleReadMore (e) {
      this.readMore = !this.readMore
    }
  },
  computed: {
    isReadMoreActive () {
      return this.readMore ? this.$t('main-form.consent-data.read-more') : this.$t('main-form.consent-data.read-more-hide')
    }
  }
}
</script>

<style lang='scss'>
.read-more-link {
  font-size: 16px !important;
  font-weight: bold;
}
</style>

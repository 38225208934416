const currentYearForSelect = () => {
  const currentYear = new Date().getFullYear()
  return `${currentYear}/${currentYear + 1}`
}
// calculate the shortest distance between two points based on the Haversine formula which is ideal for calculation of short distances.
// This formula also takes into consideration the curvature of Earth. Params in radius
const calculateShortestDistance = (latStart, lngStart, latEnd, lngEnd) => {
  const R = 6371 // Radius of the earth in km
  const dlon = lngEnd - lngStart
  const dlat = latEnd - latStart
  const a = Math.pow(Math.sin(dlat / 2), 2) + Math.cos(latStart) * Math.cos(latEnd) * Math.pow(Math.sin(dlon / 2), 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c * 1000 // Distance in meters
  return Math.round(d)
}
const validateEmailAddress = (field) => {
  // https://www.ietf.org/rfc/rfc2822.txt
  const EMAIL_REGEX = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return EMAIL_REGEX.test(String(field).toLowerCase())
}
const printeID = () => {
  const printableWindow = window.open('', 'ApplicationForm', `height=${screen.height},width=${screen.width}`)
  printableWindow.document.write(`<html><head><title>${document.title}</title></head><body><h1>Application Form</h1>${document.querySelector('#e-id').innerHTML}</body></html>`)
  printableWindow.document.close()
  printableWindow.focus()
  printableWindow.print()
  // printableWindow.close()
  return true
}
const generateRandomUUID = () => {
  var d = Date.now()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now() // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = ((d + Math.random() * 16) % 16) | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}
export {
  currentYearForSelect,
  calculateShortestDistance,
  validateEmailAddress,
  printeID,
  generateRandomUUID
}
